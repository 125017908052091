import type { IconRootProps } from './Root'
import { IconRoot } from './Root'

export const CertifiedIcon = ({ size = '1.5rem', ...props }: IconRootProps) => (
  <IconRoot
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3395 2.52345C12.5796 1.82552 11.4204 1.82552 10.6605 2.52345L9.64204 3.45888C9.25554 3.81385 8.74694 4.00125 8.22546 3.98081L6.85128 3.92697C5.826 3.88679 4.93805 4.64104 4.7991 5.67015L4.61286 7.04946C4.54218 7.57289 4.27156 8.04738 3.85911 8.37106L2.77224 9.22398C1.96131 9.86036 1.76003 11.0159 2.30705 11.8947L3.0402 13.0725C3.31842 13.5195 3.41241 14.059 3.30197 14.5754L3.01097 15.936C2.79384 16.9511 3.37341 17.9673 4.35044 18.2846L5.65993 18.7097C6.15687 18.8711 6.57148 19.2233 6.81474 19.6907L7.45576 20.9223C7.93403 21.8412 9.02326 22.2426 9.97314 21.8498L11.2463 21.3235C11.7294 21.1237 12.2706 21.1237 12.7537 21.3235L14.0269 21.8498C14.9767 22.2426 16.066 21.8412 16.5442 20.9223L17.1853 19.6907C17.4285 19.2233 17.8431 18.8711 18.3401 18.7097L19.6496 18.2846C20.6266 17.9673 21.2062 16.9511 20.989 15.936L20.698 14.5754C20.5876 14.059 20.6816 13.5195 20.9598 13.0725L21.693 11.8947C22.24 11.0159 22.0387 9.86036 21.2278 9.22398L20.1409 8.37106C19.7284 8.04738 19.4578 7.57289 19.3871 7.04946L19.2009 5.67015C19.0619 4.64104 18.174 3.88679 17.1487 3.92697L15.7745 3.98081C15.2531 4.00125 14.7445 3.81386 14.358 3.45888L13.3395 2.52345ZM15.5281 11.0325C15.8222 10.7408 15.8241 10.266 15.5325 9.97187C15.2408 9.67776 14.7659 9.6758 14.4718 9.96748L11.1428 13.2691C11.0453 13.3658 10.8882 13.3658 10.7907 13.2691L9.52814 12.0169C9.23404 11.7252 8.75917 11.7272 8.46748 12.0213C8.1758 12.3154 8.17776 12.7903 8.47186 13.082L9.73442 14.3341C10.4167 15.0107 11.5168 15.0107 12.1991 14.3341L15.5281 11.0325Z"
      fill="currentColor"
    />
  </IconRoot>
)
