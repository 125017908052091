import type { IconRootProps } from './Root'
import { IconRoot } from './Root'

export const StarIcon = ({ size = '1.5rem', ...props }: IconRootProps) => (
  <IconRoot
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    {...props}
  >
    <path d="M8.84145 5.50244C9.81961 3.57474 10.3087 2.61089 10.9452 2.26756C11.6065 1.91081 12.3935 1.91081 13.0548 2.26756C13.6913 2.61089 14.1804 3.57474 15.1586 5.50244C15.446 6.06887 15.5897 6.35208 15.7883 6.57779C15.9967 6.81466 16.249 7.00527 16.5301 7.13817C16.7979 7.26481 17.1013 7.3194 17.7082 7.42859C19.7737 7.80019 20.8064 7.98598 21.3172 8.50932C21.8479 9.05309 22.091 9.83125 21.9691 10.5955C21.8517 11.331 21.1213 12.1125 19.6603 13.6755C19.231 14.1348 19.0164 14.3644 18.8713 14.6305C18.7191 14.9098 18.6227 15.2182 18.588 15.5372C18.5549 15.8412 18.5988 16.1582 18.6864 16.7921C18.9848 18.9494 19.134 20.0281 18.8131 20.6949C18.4798 21.3877 17.8431 21.8686 17.1064 21.9842C16.3974 22.0955 15.4569 21.6146 13.5758 20.6529C13.0231 20.3703 12.7467 20.229 12.4584 20.1678C12.1559 20.1035 11.8441 20.1035 11.5416 20.1678C11.2533 20.229 10.9769 20.3703 10.4242 20.6529C8.54314 21.6146 7.60261 22.0955 6.89357 21.9842C6.15686 21.8686 5.52024 21.3877 5.18686 20.6949C4.866 20.0281 5.01519 18.9494 5.31357 16.7921C5.40124 16.1582 5.44508 15.8412 5.412 15.5372C5.37729 15.2182 5.28092 14.9098 5.12867 14.6305C4.98359 14.3644 4.76895 14.1348 4.33967 13.6755C2.87873 12.1125 2.14826 11.331 2.03091 10.5955C1.90898 9.83125 2.15215 9.05309 2.68282 8.50932C3.19355 7.98598 4.22629 7.80019 6.29176 7.42859C6.89867 7.3194 7.20213 7.26481 7.46995 7.13817C7.751 7.00527 8.00332 6.81466 8.21173 6.57779C8.41033 6.35208 8.55403 6.06887 8.84145 5.50244Z" />
  </IconRoot>
)
